<template>
  <div class="row">

    <div class="box">
      <template v-if="userData.length === 0">
        <span style="color:#c0c4cc; font-size: 13px">请选择</span>
      </template>
      <div v-for="(item, index) of userData" :key="index">
        <el-tooltip
          v-if="index < 3&&item.showText.length>6"
           effect="dark"
           :content=item.showText
            placement="top-start"
        >
         <div  class="boxItem">{{ item.showText }}</div>
        </el-tooltip>
        <div  class="boxItem" v-else-if="index < 3">{{ item.showText }}</div>
        <!-- <div v-else-if="index===3" class="boxItem" style="width:30px; cursor: pointer;">···</div> -->
        <el-tooltip
          v-else-if="index===3"
           effect="dark"
           :content="getShowText()"
            placement="top-start"
        >
          <div class="boxItem" style="width:30px; cursor: pointer;">···</div>
        </el-tooltip>
      </div>
    </div>
    <icon-button content="选择人员" icon="iconfont icontianjiayonghu" @click="visible=true"/>
    <add-personal
      :visible.sync="visible"
      :personalData='userData'
      @emitVisible="visible = false"
      title="选择人员信息"
      addTitle="新增企业人员信息"
      :hiddenAddUser="true"
      addPersonalType="interview"
      @choseSuccess="choseSuccess"
    ></add-personal>
  </div>

</template>

<script>
import addPersonal from '@/pages/business/components/add-personal.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
export default {
  components: { IconButton, addPersonal },
  props: {

  },
  data () {
    return {

      visible: false,
      userData: []
    }
  },
  // 计算属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    $attrs: {
      handler (val) {
        this.userData = JSON.parse(JSON.stringify(val.userData))
        this.userData.forEach(item => {
          this.$set(item, 'showText', item.personnerName + '(' + item.dutiesName + ')')
        })
      },
      deep: true
    }
  },
  // 方法集合
  methods: {
    choseSuccess (data) {
      this.userData = []
      if (data) {
        data.forEach((item) => {
          if (item.personnerName) {
            this.userData.push(item)
          }
        })
      }
      this.userData.forEach(item => {
        this.$set(item, 'showText', item.personnerName + '(' + item.dutiesName + ')')
      })
      this.$emit('chose', this.userData)
    },
    getShowText () {
      const showText = this.userData.map(item => item.showText)
      return showText.join(' ; ')
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {},
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped>
  .row{
    width: 100%;
    display: flex;
  }
.boxItem {
  background: #f4f4f5;
  text-align: center;
  min-height: 24px;
  line-height: 24px;
  max-width:120px;
  margin-right:6px ;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.box {
  width: calc(100% - 36px);
  background: #fff;
  border: 1px solid #D9E5EE;
  min-height: 32px;
  display: flex;
  align-items: center;
  padding: 0 9px;
  overflow: hidden;
}
.box:hover {
  border: 1px solid #2862E7 !important;
}
</style>
